import React from "react";

import { CardContent, Grid, Card, CardHeader } from "@material-ui/core";

import { IOrdenServicioOrden } from "../../../../../../AppInterfaces";
import {
  convertirTextoAFecha,
  FormatearFecha,
} from "../../../../../common/funciones/funciones";
import { TextField } from "../../../../../common/Inputs/TextField";

export default function EditorDocumento({
  documento,
  modificandoAgregandoDocumento,
  setDocumento,
}: {
  documento: IOrdenServicioOrden;
  modificandoAgregandoDocumento: boolean;
  setDocumento: (estado: IOrdenServicioOrden) => void;
}) {
  return (
    <Grid item xs={12}>
      <Card className="card">
        <CardHeader
          title={
            modificandoAgregandoDocumento
              ? "Edición de Orden"
              : "Visualizando Orden"
          }
        />
        <CardContent>
          <Grid container item spacing={1}>
            <Grid item xs={3} sm={3} id="codigo">
              <div>
                <div>
                  <strong>Codigo</strong>
                </div>
                <div>{documento.codigo}</div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} id="fecha">
              <div>
                <div>
                  <strong>Fecha</strong>
                </div>
                <div>
                  {FormatearFecha(
                    convertirTextoAFecha(documento.fecha),
                    "DD-MM-YYYY"
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} id="fechaCompromiso">
              <div>
                <div>
                  <strong>Fecha Compromiso</strong>
                </div>
                <div>
                  {FormatearFecha(
                    convertirTextoAFecha(documento.fechaCompromiso),
                    "DD-MM-YYYY"
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} id="estadoNombre">
              <div>
                <div>
                  <strong>Estado</strong>
                </div>
                <div>{documento.estadoNombre}</div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} id="clienteNombre">
              <div>
                <div>
                  <strong>Cliente</strong>
                </div>
                <div>{documento.clienteNombre}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} id="localidadNombre">
              <div>
                <div>
                  <strong>Localidad</strong>
                </div>
                <div>{documento.localidadNombre}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} id="tipoOrdenNombre">
              <div>
                <div>
                  <strong>Tipo de Orden</strong>
                </div>
                <div>{documento.tipoOrdenNombre}</div>
              </div>
            </Grid>
            <Grid item xs={12} id="observacion">
              <div>
                <div>
                  <strong>Observacion</strong>
                </div>
                <div>{documento.observacion}</div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className="card" id="cardObservacionCierre">
        <CardHeader title={"Trabajo Realizado"} />
        <CardContent>
          <Grid container item spacing={1}>
            <Grid item xs={12} id="observacionCierre">
              {modificandoAgregandoDocumento ? (
                <TextField
                  name="observacionCierre"
                  onChange={(e) => {
                    const nuevoEstado: IOrdenServicioOrden = JSON.parse(
                      JSON.stringify(documento)
                    );
                    nuevoEstado.observacionCierre = e.target.value;

                    setDocumento(nuevoEstado);
                  }}
                  value={documento.observacionCierre}
                  rows={3}
                  maxLength={8000}
                />
              ) : (
                <div>
                  <div>{documento.observacionCierre}</div>
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";

type IDocumento = {
  centroCostoId?: number;
  centroCostoNombre?: string;
  centroCostoProyectoId?: number;
  centroCostoProyectoNombre?: string;
  centroCostoSubProyectoId?: number;
  centroCostoSubProyectoNombre?: string;
};

export function AutocompleteCentroCostoSubProyecto({
  disabled = false,
  error,
  modificando = true,
  documento,
  setDocumento,
  permitirLimpiar = false,
  label = "Sub-Proyecto",
}: {
  disabled?: boolean;
  error?: string;
  modificando?: boolean;
  documento: IDocumento;
  setDocumento: (documento: any) => void;
  permitirLimpiar?: boolean;
  label?: string;
}) {
  const {
    companiaSeleccionada: { centroCostoSubProyectos, centroCostos },
  } = useContext(authContext);

  return modificando ? (
    <Autocomplete
      options={
        centroCostoSubProyectos
          .filter((reg) => !reg.isInactivo)
          .filter(
            (sbp) =>
              !documento.centroCostoId ||
              sbp.centroCostoId === documento.centroCostoId
          )
          .filter(
            (sbp) =>
              !documento.centroCostoProyectoId ||
              sbp.centroCostoProyectoId === documento.centroCostoProyectoId
          )

          .map((reg) => ({
            firstLetter: reg.nombre[0],
            id: reg.id,
            nombre: reg.nombre,
            centroCostoId: reg.centroCostoId,
            centroCostoNombre: reg.centroCostoNombre,
            centroCostoProyectoId: reg.centroCostoProyectoId,
            centroCostoProyectoNombre: reg.centroCostoProyectoNombre,
          }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? [
          {
            firstLetter: "",
            id: 0,
            nombre: "",
            centroCostoId: 0,
            centroCostoNombre: "",
            centroCostoProyectoId: 0,
            centroCostoProyectoNombre: "",
          },
        ]
      }
      groupBy={(reg) => reg.firstLetter}
      getOptionLabel={(reg) => (reg.nombre ? reg.nombre : "")}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          name="centroCostoSubProyectoNombre"
          label={label}
          error={error}
          required={
            !permitirLimpiar &&
            (documento.centroCostoNombre ||
            documento.centroCostoProyectoNombre ||
            documento.centroCostoSubProyectoNombre
              ? false
              : true)
          }
        />
      )}
      onChange={(e, value) => {
        const nuevoEstado = { ...documento };

        if (permitirLimpiar && !value) {
          setDocumento({
            ...nuevoEstado,
            centroCostoId: undefined,
            centroCostoNombre: undefined,
            centroCostoProyectoId: undefined,
            centroCostoProyectoNombre: undefined,
            centroCostoSubProyectoId: undefined,
            centroCostoSubProyectoNombre: undefined,
          });
          return;
        }

        nuevoEstado.centroCostoId = value?.centroCostoId || centroCostos[0].id;
        nuevoEstado.centroCostoNombre =
          value?.centroCostoNombre || centroCostos[0].nombre;
        nuevoEstado.centroCostoProyectoId =
          value?.centroCostoProyectoId || undefined;
        nuevoEstado.centroCostoProyectoNombre =
          value?.centroCostoProyectoNombre || undefined;
        nuevoEstado.centroCostoSubProyectoId = value?.id || undefined;
        nuevoEstado.centroCostoSubProyectoNombre = value?.nombre || undefined;

        setDocumento(nuevoEstado);
      }}
      value={{
        firstLetter: documento.centroCostoSubProyectoNombre
          ? documento.centroCostoSubProyectoNombre[0]
          : "",
        id: documento.centroCostoSubProyectoId || 0,
        nombre: documento.centroCostoSubProyectoNombre || "",
        centroCostoId: documento.centroCostoId || 0,
        centroCostoNombre: documento.centroCostoNombre || "",
        centroCostoProyectoId: documento.centroCostoProyectoId || 0,
        centroCostoProyectoNombre: documento.centroCostoProyectoNombre || "",
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>Sub-Proyecto</strong>
      </div>
      <div>{documento.centroCostoSubProyectoNombre}</div>
    </div>
  );
}

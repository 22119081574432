import React, { useState, useEffect, useCallback } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { Help } from "@mui/icons-material";
import Tour from "reactour";
import {
  Grabar,
  EliminarCustom,
  Consultar,
} from "../../../../../common/server/funcionesServidor";
import BarraHerramienta from "../../../../../common/barraHerramienta/barraHerramienta";
import Dialog from "../../../../../common/dialog/dialog";
import { validarValorAsignado } from "../../../../../common/validaciones/validaciones";
import Documento from "./Documento";
import {
  IInventarioProducto,
  IOrdenServicioOrden,
  IOrdenServicioOrdenProducto,
  IOrdenServicioTecnico,
  IOrdenServicioTecnicoLogin,
} from "../../../../../../AppInterfaces";
import Tecnicos from "./Tecnicos";
import Productos from "./Productos";
import { useQuery } from "react-query";
import tourPasos from "./Tour";
import { asignarEventoDocumento } from "src/components/common/funciones/funciones";

export default function Editor({
  documentoEditar,
  funcionCerrar,
  initModificandoAgregando,
  loginState,
}: {
  documentoEditar: IOrdenServicioOrden;
  funcionCerrar: (estado?: IOrdenServicioOrden) => void;
  initModificandoAgregando: boolean;
  loginState: IOrdenServicioTecnicoLogin;
}) {
  const collection = "OrdenServicioOrdenes";
  const [isTourAbierto, setIsTourAbierto] = useState(false);
  const [modificandoAgregandoDocumento, setModificandoAgregandoDocumento] =
    useState(false);
  const [confirmarAccionCancelar, setConfirmarAccionCancelar] = useState(false);
  const [confirmarAccionEliminar, setConfirmarAccionEliminar] = useState(false);
  const [documentoModificado, setDocumentoModificado] = useState(false);
  const [documento, setDocumento] = useState(documentoEditar);
  const [isGrabando, setIsGrabando] = useState(false);

  const { data: tecnicos } = useQuery(
    "OrdenServicioOrdenesTecnicos",
    async () =>
      await Consultar<IOrdenServicioTecnico>(
        `api/OrdenServicioTecnicos/consultarToken`,
        undefined,
        undefined,
        {
          where: `companiaId = ${loginState.companiaId} and isInactivo = 0`,
          order: "nombre asc",
          token: loginState.token,
        }
      )
  );

  const { data: productos } = useQuery(
    "InventarioProductos",
    async () =>
      await Consultar<IInventarioProducto>(
        `api/InventarioProductos/consultarToken`,
        undefined,
        undefined,
        {
          companiaId: loginState.companiaId,
          isInactivo: false,
          almacenId: loginState.almacenId,
          token: loginState.token,
        }
      )
  );

  useEffect(() => {
    setDocumento(asignarEventoDocumento(documentoEditar, loginState.nombre));
    setModificandoAgregandoDocumento(initModificandoAgregando);
  }, [documentoEditar, initModificandoAgregando, loginState.nombre]);

  const toogleTour = () => {
    setIsTourAbierto((isTourAbierto) => !isTourAbierto);
  };

  //Acciones de la barra de herramientas
  const accionModificar = () => {
    if (!documento.isCerrado) {
      if (
        !documento.productos.some((e) => e.conduceId) &&
        !documento.facturaId
      ) {
        setModificandoAgregandoDocumento(true);
        setDocumento(asignarEventoDocumento(documento, loginState.nombre));
      } else {
        validarValorAsignado(
          "conduceId",
          0,
          "No es posible modificar una orden con documentos relacionados"
        );
      }
    } else {
      validarValorAsignado(
        "isCerrado",
        0,
        "No es posible modificar una orden cerrada"
      );
    }
  };

  const accionEliminarConfirmar = () => setConfirmarAccionEliminar(true);
  const accionEliminar = async () => {
    if (!documento.id) {
      if (documento.evento) delete documento.evento;
      if (documento.tableData) delete documento.tableData;
      setConfirmarAccionEliminar(false);
      return;
    }

    let respuesta = await EliminarCustom(`api/${collection}/eliminarToken`, {
      ...documento,
      evento: {
        usuario: loginState.nombre,
        estadoAnterior: JSON.stringify(documento),
      },
      token: loginState.token,
    });

    if (respuesta.length) {
      funcionCerrar();
    } else setConfirmarAccionEliminar(false);
  };
  const accionEliminarCancelar = () => setConfirmarAccionEliminar(false);

  const accionCancelarConfirmar = () =>
    documentoModificado ? setConfirmarAccionCancelar(true) : accionCancelar();
  const accionCancelar = () => funcionCerrar(documento);
  const accionCancelarCancelar = () => setConfirmarAccionCancelar(false);

  const accionGrabar = useCallback(async () => {
    //Validaciones
    let error = {};

    const documentoProductos: IOrdenServicioOrdenProducto[] = JSON.parse(
      JSON.stringify(documento.productos.filter((e) => !e.eliminar))
    );

    if (documentoProductos.length) {
      error = {
        ...error,
        ...validarValorAsignado(
          "tecnicoAsignado",
          documentoProductos.some((a) => !a.productoId) === true
            ? undefined
            : true,
          "Existen productos sin confirmar selección"
        ),
      };

      error = {
        ...error,
        ...validarValorAsignado(
          "tecnicoAsignado",
          documentoProductos.some((e) => e.cantidad <= 0) === true
            ? undefined
            : true,
          "Existen productos con una cantidad incorrecta"
        ),
      };
    }

    if (JSON.stringify(error) !== "{}") {
      setIsGrabando(false);
      return;
    }
    //Validaciones

    const nuevoDoc: IOrdenServicioOrden[] = await Grabar(
      `api/${collection}/crearModificarToken`,
      { ...documento, token: loginState.token },
      true
    );

    setIsGrabando(false);

    if (nuevoDoc.length) {
      setDocumento(asignarEventoDocumento(nuevoDoc[0], loginState.nombre));
      setDocumentoModificado(false);
      setModificandoAgregandoDocumento(false);
    }
  }, [documento, loginState.token, loginState.nombre]);
  //Acciones de la barra de herramientas

  const handleAccionGrabar = () => {
    setIsGrabando(true);
    accionGrabar();
  };

  return (
    <>
      <Tooltip style={{ zIndex: 2000 }} title="Tour" aria-label="Tour">
        <Help
          className="botonTour"
          onClick={toogleTour}
          fontSize="large"
          id="botonTour"
        />
      </Tooltip>
      <Tour
        steps={tourPasos}
        isOpen={isTourAbierto}
        onRequestClose={toogleTour}
      />

      <BarraHerramienta
        accionGrabar={handleAccionGrabar}
        accionCancelar={accionCancelarConfirmar}
        accionModificar={accionModificar}
        accionEliminar={accionEliminarConfirmar}
        botonModificarDesHabilitar={modificandoAgregandoDocumento}
        botonEliminarDesHabilitar={modificandoAgregandoDocumento}
        botonGrabarDesHabilitar={!modificandoAgregandoDocumento}
        isGrabando={isGrabando}
      />

      <Dialog
        open={confirmarAccionCancelar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea cancelar el proceso?, Existen cambios sin grabar si procede serán descartados."
        textoBtn1="Continuar y Descartar Cambios"
        textoBtn2="Cancelar"
        accionDialogBtn1={accionCancelar}
        accionDialogBtn2={accionCancelarCancelar}
      />

      <Dialog
        open={confirmarAccionEliminar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea eliminar el registro completo?"
        textoBtn1="Continuar y Eliminar Registro"
        textoBtn2="Cancelar"
        accionDialogBtn1={accionEliminar}
        accionDialogBtn2={accionEliminarCancelar}
      />

      <Grid container item spacing={1}>
        <Documento
          documento={documento}
          modificandoAgregandoDocumento={modificandoAgregandoDocumento}
          setDocumento={setDocumento}
        />
      </Grid>

      <Productos
        documento={documento}
        setDocumento={setDocumento}
        setDocumentoModificado={setDocumentoModificado}
        productos={productos ?? []}
        modificandoAgregandoDocumento={modificandoAgregandoDocumento}
        loginState={loginState}
      />

      <Tecnicos
        documento={documento}
        setDocumento={setDocumento}
        setDocumentoModificado={setDocumentoModificado}
        tecnicos={tecnicos ?? []}
        modificandoAgregandoDocumento={false}
        usuarioId={loginState.id}
        companiaId={loginState.companiaId}
      />

      <BarraHerramienta
        accionGrabar={handleAccionGrabar}
        accionCancelar={accionCancelarConfirmar}
        accionModificar={accionModificar}
        accionEliminar={accionEliminarConfirmar}
        botonNuevoDesHabilitar={modificandoAgregandoDocumento}
        botonModificarDesHabilitar={modificandoAgregandoDocumento}
        botonEliminarDesHabilitar={modificandoAgregandoDocumento}
        botonGrabarDesHabilitar={!modificandoAgregandoDocumento}
        isGrabando={isGrabando}
      />
    </>
  );
}
